<template>
  <div class="tableOptions">
    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon small class="mr-2 iconeEditar" @click="dialogEditar = true">
            mdi-pencil
          </v-icon>
        </span>
      </template>
      <span>Editar</span>
    </v-tooltip>

    <v-tooltip bottom color="error">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon class="iconeDeletar" small @click="dialogAPagar = true">
            mdi-delete
          </v-icon>
        </span>
      </template>
      <span>Apagar</span>
    </v-tooltip>

    <v-dialog
      v-model="dialogEditar"
      width="600px"
      class="modelDialog"
      persistent
    >
      <v-card class="card rounded-lg">
        <div class="banner-edit">
          <h4>Editar Informações</h4>
        </div>

        <AlertError v-model="displayError" :resource="displayError" />

        <div class="space"></div>

        <v-form
          @submit.prevent="edit"
          v-model="valid"
          lazy-validation
          ref="form"
        >
          <v-text-field
            label="Nome"
            placeholder="Placeholder"
            outlined
            v-model="resource.name_person"
            :rules="name_personRules"
            dense
          ></v-text-field>
          <v-text-field
            label="Nome Social"
            placeholder="Placeholder"
            outlined
            v-model="resource.social_name"
            :rules="social_nameRules"
            dense
          ></v-text-field>
          
          <v-select
            clearable
            outlined
            dense
            v-model="id_person_type"
            :items="list_person_types"
            label="Selecione Tipo ..."
            item-text="person_type"
            item-value="id"
            :rules="person_typeRules"
          ></v-select>

          <v-select
            clearable
            outlined
            dense
            v-model="id_kinship"
            :items="list_profiles"
            label="Selecione Parentesco..."
            item-text="kinship_name"
            item-value="id"
            :rules="profileRules"
          ></v-select>

          <v-card-actions class="cardFooter">
            <v-spacer></v-spacer>

            <v-btn
              class="rounded-lg btn"
              color="red"
              text
              elevation="0"
              @click="cancelEdit"
            >
              <span>Cancelar</span>
            </v-btn>

            <v-btn
              class="rounded-lg"
              color="success"
              elevation="0"
              type="submit"
            >
              <span class="textBtn">Enviar dados</span>
              <v-icon small class="ml-2">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogAPagar"
      width="400px"
      class="modelDialog"
      persistent
    >
      <v-card class="card rounded-lg">
        <div class="banner-delete">
          <h4>Deletar Usuário</h4>
        </div>

        <div class="textInfo">
          <h6 class="textDelete">
            Deseja deletar <span class="userName">{{ resource.name }}</span> dos
            usuários cadastrados?
          </h6>
          <h6 class="alertText">(Essa ação não pode ser revertida)</h6>
        </div>

        <v-card-actions class="cardFooter">
          <v-btn
            class="rounded-lg btn"
            color="red"
            text
            elevation="0"
            @click="dialogAPagar = false"
          >
            <span>Cancelar</span>
          </v-btn>

          <v-btn
            class="rounded-lg"
            color="#27ae60"
            elevation="0"
            type="submit"
            @click="deletar"
          >
            <span class="textBtn">Confirmar</span>
            <v-icon color="#FFFFFF" small class="ml-2">mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertSuccess v-model="displaySuccess" :resource="displaySuccess" />
  </div>
</template>
---------------------------------------------------------------------------------------------
<script>
import axios from "@/axios";
import AlertSuccess from "../alerts/Success-component.vue";
import AlertError from "../alerts/Error-component.vue";

export default {
  data() {
    return {
      list_kinships: [],
      list_profiles: [],
      id_person_type: "",
      id_kinship: "",
      valid: true,
      name_personRules: [(v) => !!v || "O campo é obrigatório"],
      kinshipRules: [(v) => !!v || "O campo é obrigatório"],
      person_typesRules: [(v) => !!v || "O campo é obrigatório"],
      dialogEditar: false,
      dialogAPagar: false,
      displayError: {
        display: false,
        message: [],
      },
      displaySuccess: {
        display: false,
        message: [],
      },
    };
  },

  props: {
    resource: {
      type: Object,
    },
  },

  components: {
    AlertSuccess,
    AlertError,
  },
  mounted() {
    this.getAllPersonTypes();
    this.getAllKinships();
  },

  methods: {
    edit() {
      if (this.$refs.form.validate() == true) {
        const dados = new Object();
        dados.id = this.resource.id;
        dados.name_person = this.resource.name_person;
        

        axios
          .put(`/pacient/${dados.id}`, dados)
          .then((response) => {
            this.displaySuccess.message = response.data.message;
            this.displaySuccess.display = true;
            this.dialogEditar = false;
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          });
      }
    },

    getAllKinships() {
      axios.get("/kinships").then((response) => {
        this.list_kinships = response.data;
      });
    },

    getAllPersonTypes() {
      axios.get("/person_types").then((response) => {
        this.list_person_types = response.data;
      });
    },
    cancelEdit() {
      this.dialogEditar = false;      
    },

    deletar() {
      this.dialogAPagar = false;
      this.$emit("eventname", this.resource.id);
    },
  },
};
</script>
---------------------------------------------------------------------------------------------
<style scoped>
.space {
  height: 10px;
}

.iconeEditar:hover {
  color: #54c6eb;
}

.iconeDeletar:hover {
  color: #ad2831;
}

.card {
  padding: 20px;
}

.banner-edit {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgba(95, 195, 228, 1) 0%,
    rgba(93, 148, 229, 1) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-delete {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgb(235, 30, 57) 0%,
    rgb(248, 96, 36) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-edit > h4,
.banner-delete > h4 {
  color: #ffffff;
  padding: 10px;
}

.textInfo {
  padding: 15px;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 8px;
  text-align: center;
}

.textDelete {
  font-family: "Poppins";
  font-weight: 400;

  font-size: 14px;
}

.userName {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.alertText {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;

  margin-top: 15px;

  color: #f44336;
}

.cardFooter {
  display: flex;
  justify-content: center;

  margin-top: 20px;
}

.cardFooter {
  padding: 0 !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}
</style>
